.about {
  position: relative;
  grid-row: 2 / 12;
  grid-column: 2 / 12;
  background: url("../assets/images/bg01.jpg") no-repeat center center fixed;
  background-size: cover;
  z-index: 20;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  overflow: hidden;
}

#vanta {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 1.2s ease-in-out, filter 1.2s ease-in-out;
}

.header {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header > img {
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  border: 1px solid #ffff00;
  box-shadow: rgb(28 32 93 / 24%) 0px 2px 8px 0px;
}

.header > h1 {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 2rem;
  background-color: #000a;
  color: #ffff00;
  padding: 0.4rem 0.8rem;
  border: 1px solid #ffff00;
}

ul {
  box-sizing: border-box;
  list-style: none;
  width: 100%;
  padding: 0 2rem;
  max-width: 480px;
}

ul > li {
  background-color: #ffff00aa;
  /* backdrop-filter: blur(10px) saturate(160%) contrast(180%); 
  -webkit-backdrop-filter: blur(10px) saturate(160%) contrast(180%); */
  border-radius: 10rem;
  padding: 1rem;
  margin: 1.4rem 0;
  box-shadow: rgb(28 32 93 / 24%) 0px 2px 8px 0px;
}

ul > li > a {
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem;
}

a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
}

@media (max-width: 900px) { 
  .about {
    height: 100%;
    width: 100%;
  }
  .header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header > img {
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
    border: 1px solid #ffff00;
    box-shadow: rgb(28 32 93 / 24%) 0px 2px 8px 0px;
  }
  ul > li > a {
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    padding: 0.6rem;
  }
}