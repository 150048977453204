.appear-left {
  opacity: 0;
  transition: transform 0.6s ease-in, opacity 0.6s ease-in;
  --start-position: calc(-100% * var(--i));
  transform: translateX(var(--start-position));
}

.appear-left.show {
  transform: translateX(0);
  opacity: 1;
}

.image-box {
  display: block;
  height: 40%;
  width: 100%;
}

.image-bg {
  display: none;
  height: 100%;
  width: 100%;
}


/* here are the rules for small windows */
@media (max-width: 900px) { 
  .appear-left {
    opacity: 1;
    --start-position: 0;
  }
  .image-box {
    display: none;
  }
  .image-bg {
    display: block;
    height: 100%;
    width: 100%;
  }
}