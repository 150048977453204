.experience {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

section{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Outfit', sans-serif;
    font-size: 7vmin;
}

.wrapper {
  display:flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.container, .title-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100%;
  width: 100%;
  max-height: 1080px;
  max-width: 1400px;
}

.page-title {
  grid-row: 4 / 6;
  grid-column: 2 / 7;
  background-color: yellow;
  border-radius: 10px;
  z-index: 10;
}

.page-subtitle {
  grid-row: 6 / 9;
  grid-column: 2 / 6;
  background-color: yellow;
  border-radius: 10px;
  z-index: 10;
}

.section-title {
  grid-row: 3 / 5;
  grid-column: 2 / 7;
  background-color: yellow;
  border-radius: 10px;
  padding: 0 2rem;
  z-index: 10;
}

.project-box {
  grid-row: 5 / 11;
  grid-column: 2 / 12;
  border-radius: 10px;
  z-index: 10;
}


/* here are the rules for small windows */
@media (max-width: 900px) { 
  .experience {
    height: 50%;
    top: 25%;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 2rem;
    height: 100%;
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7rem 1rem 2rem 1rem;
    height: 100%;
    width: 100%;
  }

  .page-title {
    background-color: yellow;
    border-radius: 10px;
    z-index: 10;
  }

  .page-subtitle {
    grid-row: 6 / 9;
    grid-column: 2 / 6;
    background-color: yellow;
    border-radius: 10px;
    z-index: 10;
  }

  .section-title {
    background-color: yellow;
    border-radius: 10px;
    padding: 1rem;
    z-index: 10;
  }
}
