.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 33%;
  background-color: white;
  z-index: 40;
}

.menu-transition {
	transition: transform 0.5s ease;
  transform: translateX(-100%);
	overflow: hidden;
}

.menu-transition.open {
	display: block;
  transform: translateX(0%);
	animation: transform 0.5s ease;
}

.sidebar-logo {
  display: grid;
  place-items: center;
  height: 6rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Mochiy Pop P one', sans-serif;
}

.sidebar-menu {
  position: relative;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  place-content: flex-start;
  padding: 1rem 3rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #555555;
}

.sidebar-menu-item.animated {
  transition: color 0.3s ease-in-out
}

.sidebar-menu-item.active {
  color: white
}

.sidebar-menu-item-icon {
  margin-right: 1rem;
}

.sidebar-menu-indicator {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 40px);
  border-radius: 10px;
  background-color: #308efe;
  z-index: -1;
  transform: translateX(-50%);
}

.sidebar-menu-indicator.animated {
  transition: 0.3s ease-in-out
}

/* here the rules for small windows */
@media (max-width: 500px) { 
  .sidebar {
    width: 100vw;
  }
}
